import { useEffect, useMemo, useState } from "react"
import axios from "axios"
import { useLigonierAuth } from "@utils/ligonierAuth"

export interface UseFavorites {
  addFavorite: (entryId: string, contentType: string) => Promise<boolean>
  checkFavorite: (itemId: string) => boolean
  removeFavorite: (entryId: string) => Promise<boolean>
}

interface ResponseItem {
  entry_id: string
  content_type: string
  id: number
}

export const useFavorites = (): UseFavorites => {
  const [favorites, setFavorites] = useState(new Map())
  const user = useLigonierAuth(true)

  const authHeaders = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${user.accessToken}`
      }
    }),
    [user]
  )

  const baseUrl = process.env.GATSBY_DJANGO_REDIRECT_URL

  const removeFavorite = async (entryId: string): Promise<boolean> => {
    const item = favorites.get(entryId)

    if (!item) {
      return false
    }

    const removeFavoriteUrl = `${baseUrl}/api/v1/user/favorite/detail/${item.id}/`
    let response

    try {
      response = await axios.delete(removeFavoriteUrl, authHeaders)
    } catch (e) {
      console.log(`addFavorite Error: `, e.message)
      response = null
    }

    if (response?.status === 204) {
      favorites.delete(entryId)
      setFavorites(new Map(favorites))
    }

    return true
  }

  const addFavorite = async (
    entryId: string,
    contentType: string
  ): Promise<boolean> => {
    const addFavoriteUrl = `${baseUrl}/api/v1/user/favorite/create/`
    const postData = {
      content_type: contentType,
      entry_id: entryId
    }

    let response

    try {
      response = await axios.post(addFavoriteUrl, postData, authHeaders)
    } catch (e) {
      console.log(`addFavorite Error: `, e.message)
      response = null
    }

    if (!response) return false

    const result = response?.data as ResponseItem

    if (result.entry_id === entryId) {
      setFavorites(
        new Map(
          favorites.set(entryId, {
            id: result.id,
            content_type: contentType
          })
        )
      )

      return true
    }

    return false
  }

  const checkFavorite = (itemId: string): boolean => {
    return favorites.get(itemId)
  }

  useEffect(() => {
    const fetchFavorites = async () => {
      const fetchFavoritesUrl = `${baseUrl}/api/v1/user/favorite/list/`

      let response

      try {
        response = await axios.get(fetchFavoritesUrl, authHeaders)
      } catch (e) {
        console.log(`addFavorite Error: `, e.message)
        response = null
      }

      const data = (response?.data || []) as ResponseItem[]

      const list = new Map()

      if (Array.isArray(data)) {
        data.forEach(({ entry_id, content_type, id }) => {
          list.set(entry_id, { id, content_type })
        })
      }

      setFavorites(list)
    }

    fetchFavorites()
  }, [authHeaders, baseUrl])

  return {
    addFavorite,
    checkFavorite,
    removeFavorite
  }
}
